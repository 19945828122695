/*
 * @Author: zhongtao
 * @LastEditors: zhongtao
 */

class searchFrom {
  validatePhone = (rule, value, callback) => {
    const phone = value && value.replace(/\s/g, "");//去除空格
    //校验手机号，号段主要有(不包括上网卡)：130~139、150~153，155~159，180~189、170~171、176~178。14号段为上网卡专属号段
    const regs = /^((13[0-9])|(17[0-1,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/;
    const reg = /^\d{3}-\d{8}$|^\d{4}-\d{7,8}$/;
    if (!value) {
      callback([new Error('请输入联系电话')]);
    } else if (!regs.test(phone) && !reg.test(phone)) {
          callback([new Error('电话输入不正确')]);
      } else {
          callback();
      }
  }

  constructor(type) {
    if (type === 'form') {
      this.userId = 0
      this.userName = ''
      this.loginName = ''
      this.phonenumber = ''
      this.remark = ''
      this.status = ''
      this.roleIds = ''
      this.merchantsId = []
      this.sportsProjectCode = []
      // this.password = ''
      // this.checkPassword = ''
    } else if (type === 'rule') {
      this.loginName = [
        {
          required: true,
          message: '请输入用户名',
          trigger: 'blur',
        },
      ]
      this.userName = [
        {
          required: true,
          message: '请输入昵称',
          trigger: 'blur',
        },
      ]
      this.phonenumber = [
        {
          required: true,
          message: '请输入手机号',
          trigger: ['blur', 'change'],
        },
        {
          min: 11,
          max: 11,
          message: '长度在11个字符',
          trigger: 'blur',
        },
      ]
      this.status = [
        {
          required: true,
          message: '请选择状态',
          trigger: 'change',
        },
      ]
      this.roleIds = [
        {
          required: true,
          message: '请选择角色',
          trigger: 'change',
        },
      ]
      this.merchantsId = [
        {
          required: true,
          message: '请选择场馆权限',
          trigger: 'change',
        },
      ]
      this.sportsProjectCode = [
        {
          required: true,
          message: '请选择运动项目',
          trigger: 'change',
        },
      ]
      // this.password = [
      //   {
      //     required: true,
      //     message: '请输入密码',
      //     trigger: 'blur',
      //   },
      // ]
      // this.checkPassword = [
      //   {
      //     required: true,
      //     message: '请再次输入密码',
      //     trigger: 'blur',
      //   },
      // ]
    }
  }
}
export default searchFrom
