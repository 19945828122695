import { render, staticRenderFns } from "./indexNew.vue?vue&type=template&id=d008fccc&scoped=true&"
import script from "./indexNew.vue?vue&type=script&lang=js&"
export * from "./indexNew.vue?vue&type=script&lang=js&"
import style0 from "./indexNew.vue?vue&type=style&index=0&id=d008fccc&lang=less&scoped=true&"
import style1 from "./indexNew.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d008fccc",
  null
  
)

export default component.exports