<template>
  <div class="wrap">
    <div class="search-container">
      <el-row>
        <el-col :span="18">
          <el-form ref="form" :model="form" label-width="80px">
            <el-input
              clearable
              v-model="form.loginName"
              placeholder="请输入关键字"
              style="width: 250px; margin-left: 17px"
            ></el-input>
          </el-form>
        </el-col>
        <el-col :span="6" style="text-align: right">
          <el-button @click="searchEvent" class="search-button">{{
            $t("search")
          }}</el-button>
          <el-button @click="cleanForm" class="clean-button">{{
            $t("clean")
          }}</el-button>
          <el-button
            class="search-button add-btn btn-round-style"
            v-if="buttonAuth.includes('system:user:add')"
            @click="changeAddDialog(true)"
            >{{ $t("add") }}</el-button
          >
        </el-col>
      </el-row>
    </div>
    <el-row style="margin-left: 16px">
      <el-col :span="24">
        <div class="table-container">
          <el-table
            :data="tableData"
            :height="tableHeight"
            style="width: 100%"
            border
            :row-class-name="tableRowClassName"
            @sort-change="sort_change"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="index"
              align="center"
              label="序号"
              width="55"
            >
            </el-table-column>
            <!-- <el-table-column type="selection" align="center" width="55">
            </el-table-column> -->
            <el-table-column
              prop="loginName"
              align="center"
              label="账号"
              min-width="120"
            >
            </el-table-column>
            <el-table-column
              prop="phonenumber"
              align="center"
              label="手机号"
              min-width="120"
            >
            </el-table-column>
            <el-table-column
              prop="roles"
              align="center"
              label="角色"
              min-width="120"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.roles">{{
                  scope.row.roles[0].roleName
                }}</span>
                <span v-else>暂无</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="isShow"
              align="center"
              min-width="220"
              prop="projectName"
              label="负责运动项目"
              :key="1"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.projectName">{{
                  scope.row.projectName
                }}</span>
                <span v-else>暂无</span>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :key="1"
              align="center"
              min-width="220"
              prop="projectName"
              label="场馆权限"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.projectName">{{
                  scope.row.projectName
                }}</span>
                <span v-else>暂无</span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              align="center"
              prop="email"
              :label="$t('email')">
            </el-table-column> -->
            <el-table-column align="center" width="320px" :label="$t('option')">
              <template slot-scope="scope">
                <!-- <el-button class="default-button" @click="changeAddDialog(true, true, scope.row)">{{$t('edit')}}</el-button> -->
                <!-- <el-button class="default-button reset-button" v-if="buttonAuth.includes('system:user:resetPwd')" @click="openConfirmDialog({
                  ...scope.row,
                  ...{
                    password: 123456
                  }})">{{$t('reset_password')}}</el-button> -->
                <el-button
                  class="default-button reset-button "
                  v-if="buttonAuth.includes('system:user:resetPwd')"
                  @click="openConfirmDialog(scope.row)"
                  >{{ $t("reset_password") }}</el-button
                >
                <el-button
                  class="default-button"
                  v-if="buttonAuth.includes('system:user:edit')"
                  @click="changeAddDialog(true, true, scope.row)"
                  :isEdit="addDialog.isEdit"
                  >{{ $t("edit") }}</el-button
                >
                <el-button
                  class="default-button"
                  v-if="buttonAuth.includes('system:user:remove')"
                  @click="openDeleteDialog([scope.row.userId])"
                  >{{ $t("delete") }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="page-container">
          <div class="">
            <!-- <el-button
              class="default-button"
              v-if="buttonAuth.includes('system:user:remove')"
              @click="openDeleteDialog(selectedArr.map((item) => item.userId))"
              >{{ $t("delete") }}</el-button
            >
            <el-button
              class="default-button"
              v-if="buttonAuth.includes('system:user:add')"
              @click="changeAddDialog(true)"
              >{{ $t("add") }}</el-button
            > -->
          </div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="form.total"
          >
          </el-pagination>
        </div>
      </el-col>
    </el-row>
    <!-- 添加/修改弹窗 -->
    <add-dialog
      ref="addDialog"
      :Language="Language"
      :show="addDialog.visible"
      :isEdit="addDialog.isEdit"
      @closeDialog="changeAddDialog"
    ></add-dialog>
    <!-- 修改密码 弹窗 -->
    <password-dialog
      ref="passwordDialog"
      :passwordData="passwordDialog.passwordData"
      :Language="Language"
      :show="passwordDialog.visible"
      :isEdit="passwordDialog.isEdit"
      @closeDialog="passwordConfirmDialog"
    ></password-dialog>
    <!-- 删除确认弹窗 -->
    <confirm-dialog
      ref="confirmDialog"
      :Language="Language"
      :show="confirmDialog.visible"
      :type="confirmDialog.type"
      @sureDialog="sureConfirmDialog"
      @closeDialog="changeConfirmDialog"
    ></confirm-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import apis from "@/apis";
import mixin from "@/mixins/index";
import confirmDialog from "@/components/confirmDialog.vue";
import passwordDialog from "./dialog/changePassword.vue";
import addDialog from "./dialog/addDialog.vue";
import UserSearchClass from "./userSearchClass";

export default {
  components: {
    addDialog,
    confirmDialog,
    passwordDialog,
  },
  mixins: [mixin],
  data() {
    return {
      form: new UserSearchClass("form"), // 实例化一个表单的变量
      tableData: [],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      passwordDialog: {
        passwordData: {
          loginName: "",
          password: "",
        },
        visible: false,
        isEdit: false,
      },
      permissions: [],
      confirmDialog: {
        visible: false,
        type: 1,
        data: {},
      },
    };
  },
  computed: {
    ...mapState(["Language", "venueId", "ROLES", "merchantAccountDetailInfoDTO"]),
    isShow() {
      return this.ROLES[0].dataPermission === "2";
    },
  },
  created() {
    this.loadPermissions();
  },
  methods: {
    /**
     * @function 搜索方法
     */
    searchData() {
      this.form = {
        ...this.form,
        merchantId: this.merchantAccountDetailInfoDTO.merchantId,
      };
      this.$http.get(apis.userList, { params: this.form }).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows;
          this.form.total = res.data.total;
        }
      });
    },
    /**
     * @function 部门选择方法
     */
    permissionsCheck(data) {
      this.form.deptId = data.value;
      this.form.pageNum = 1;
      this.form.pageSize = 10;
      this.searchData();
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new UserSearchClass("form");
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
    loadPermissions() {
      this.$http.get(apis.dept_list_enable).then((res) => {
        this.buildtree(res.data.rows, this.permissions, 0);
      });
    },
    buildtree(list, arr, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          const child = {
            key: item.deptId.toString(),
            value: item.deptId.toString(),
            title: item.deptName,
            children: [],
          };
          this.buildtree(list, child.children, item.deptId);
          if (child.children.length === 0) {
            delete child.children;
          }
          arr.push(child);
        }
      });
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus, isEdit = false, data) {
      console.log(this.$refs["addDialog"]);
      console.log('aaaaaaaaaaaaaa');
      if (dialogStatus) {
        // this.$refs['addDialog'].getRoleData()
        this.addDialog.isEdit = isEdit;
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.$refs["addDialog"].getDataQuery(data);
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.searchEvent();
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus;
      });
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      this.$http
        .post(
          this.confirmDialog.type === 1
            ? `${apis.userRemove}?ids=${this.confirmDialog.data.ids}`
            : apis.resetPassword,
          this.confirmDialog.data,
        )
        .then((res) => {
          if (res.data.code === 0) {
            if (this.confirmDialog.type === 2) {
              this.$message.success(`${this.$t("reset_success")}123456`);
            }
            this.$refs.confirmDialog.closeDialog();
          }
        });
    },
    /**
     * @function 打开删除弹窗
     * @param {Array} id 选中的ID数组
     */
    openDeleteDialog(id) {
      if (id.length === 0) {
        this.$message.error("请先选择数据");
        return;
      }
      this.confirmDialog.data.ids = id.join(",");
      this.changeConfirmDialog(true, 1);
    },
    /**
     * @function 打开确认重置密码弹窗
     * @param {Array} data 要重置密码的值
     */
    openConfirmDialog(data) {
      this.passwordDialog.passwordData = {
        ...this.passwordDialog.passwordData,
        ...data,
      };
      console.log(
        "this.passwordDialog.passwordData",
        this.passwordDialog.passwordData,
      );
      this.$nextTick(() => {
        this.passwordDialog.visible = true;
        this.$refs.passwordDialog.getInfo();
      });
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type;
      this.confirmDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.confirmDialog.data.ids = "";
        this.searchEvent();
      }
    },
    passwordConfirmDialog() {
      this.passwordDialog.visible = false;
    },
    /**
     * @function 修改用户启用状态
     * @param {Object} data 用户信息
     */
    changeStatus(data) {
      const form = {
        userId: data.userId,
        status: data.status,
      };
      this.$http.post(apis.userStatus, form);
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.page-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
