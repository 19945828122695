<template>
  <div class="dialog-container">
    <el-dialog
      :title="`${isEdit ? $t('edit') : $t('add')}`"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form ref="form" :model="form" label-width="110px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="账号" prop="loginName">
              <el-input
                clearable
                v-model="form.loginName"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item label="密码" prop="password">
              <el-input
                clearable
                v-model="form.password"
                maxlength="20"
                show-password
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="确认密码" prop="checkPassword">
              <el-input
                clearable
                v-model="form.checkPassword"
                maxlength="20"
                show-password
              ></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item label="用户名" prop="userName">
              <el-input
                clearable
                v-model="form.userName"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="手机号" prop="phonenumber">
              <el-input
                clearable
                v-model="form.phonenumber"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="角色" prop="roleIds">
              <el-select v-model="form.roleIds">
                <el-option
                  :value="action.roleId + ''"
                  :label="action.roleName"
                  v-for="action in roleAll"
                  :key="action.roleId"
                  @click.native="getRloe(action.dataPermission)"
                ></el-option>
                <!-- @click.native="getRloe(action.dataPermission)" -->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!-- <el-form-item
              label="场馆权限"
              prop="merchantsId"
              v-if="currentVal === '2'"
            >
              <el-select v-model="form.merchantsId" multiple>
                <el-option
                  :value="item.id"
                  :label="item.venueName"
                  v-for="item in venueList"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item
              label="负责运动项目"
              prop="sportsProjectCode"
              v-if="currentVal === '3'"
            >
              <el-select v-model="form.sportsProjectCode" multiple>
                <el-option
                  v-for="item in sportList"
                  :key="item.sportsProjectCode"
                  :label="item.sportsProjectName"
                  :value="item.sportsProjectCode"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('status')" prop="status">
              <el-select v-model="form.status">
                <el-option :value="'0'" label="正常"></el-option>
                <el-option :value="'1'" label="禁用"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="描述" prop="remark">
              <el-input
                clearable
                type="textarea"
                v-model="form.remark"
                maxlength="200"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{
          $t("sure")
        }}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{
          $t("cancel")
        }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mixin from "@/mixins/dialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
      depts: [], // 部门
      roleAll: [], // 角色树状的值
      sportList: [], // 查询所有运动项目
      currentVal: "",
    };
  },
  watch: {
    // eslint-disable-next-line
    "form.deptId"() {
      if (this.$refs.cascader) {
        this.$refs.cascader.dropDownVisible = false;
      }
    },
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "venueId", "venueList", "merchantAccountDetailInfoDTO"]),
    deptsFormat() {
      console.log(this.depts);
      const result = [];
      const format = (arr) => {
        arr.forEach((item) => {
          if (!item.children) {
            result.push(item);
          } else {
            format(item.children);
          }
        });
      };
      if (this.depts.length > 0) {
        format(this.depts);
      }
      return result;
    },
  },
  created() {
    this.getPermissions();
    // this.getRoleData();
    this.getSportList();
    console.log(this.rules);
  },
  activated() {
    // this.getRoleData();
    // this.getSportList();
  },
  methods: {
    openDialog() {
      if (!this.isEdit) {
        this.currentVal = "";
      }
      this.getRoleData();
    },
    getSportList() {
      this.$http
        .get(`${apis.getSportsNewProject}?id=${this.venueId}`)
        .then((res) => {
          if (res.data.code === 0) {
            this.sportList = res.data.rows;
            console.log('bbbbbbbbbb');
            console.log(this.sportList);
          } else {
            this.$message.warning(res.data.msg);
          }
        });
    },
    getRloe(val) {
      this.currentVal = val;
      if (val === "3") {
        this.getSportList();
      }
    },
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.form = new AddDialogClass("form");
      console.log("我初始化了");
    },
    /* eslint-disable */
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 用户不能是中文
      // if (/[\u4E00-\u9FA5]/g.test(this.form.loginName)) {
      //   this.$message.error("用户名不能是中文");
      // }else{
      //   console.log(/^[a-zA-Z0-9]{8-14}$/.test(this.form.password));
      //   if(/^(?![^A-z]+$)(?!\D+$)[A-z\d]{8,14}$/.test(this.form.password)){
      //     if(this.form.checkPassword != this.form.password){
      //       this.$message.error("两次密码不一致");
      //       return false;
      //     }
      //   }else{
      //     this.$message.error("密码需是8-14位字母与数字");
      //     return false;
      //   }
      // }
      if (/[\u4E00-\u9FA5]/g.test(this.form.loginName)) {
        this.$message.error("账号不能是中文");
        return false;
      }
      
      const params = { ...this.form };
      console.log(params);
      let arr = [];
      arr.push(params.roleIds);
      console.log(arr);
      params.roleIds = arr;
      params.merchantId = this.merchantAccountDetailInfoDTO.merchantId
      // const sportsCode = this.arrayToString(params.sportsCode);
      // params.sportsCode = sportsCode;
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http
            .post(this.isEdit ? apis.user_update : apis.user_save, params)
            .then((res) => {
              if (res.data.code === 0) {
                // 调用关闭方法，不要再写一次
                this.closeDialog();
              }
            });
        }
      });
      console.log(`我执行了点确定的业务需求`);
    },
    // 数组转字符串
    arrayToString(arr = []) {
      let str = "";
      if (arr.length === 1) {
        str = arr[0];
      } else if (arr.length > 0) {
        str = arr.join(",");
      }
      return str;
    },
    // 字符串转数组
    stringToArray(str = "") {
      let arr = [];
      if (str) {
        console.log("+++++str:", str, !str, str.indexOf(","));
        if (str.indexOf(",") > -1) {
          arr = str.split(",");
        } else {
          arr[0] = str;
        }
      }
      console.log("++++++++str:", str, "+++++arr:", arr);
      return arr;
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      console.log(data);
      this.form.userId = data.userId;
      this.form.userName = data.userName;
      this.form.loginName = data.loginName;
      this.form.phonenumber = data.phonenumber;
      this.form.status = data.status;
      this.form.remark = data.remark;
      this.form.deptId = data.deptId;
      this.form.roleIds = data.roleIds[0] + "";
      this.form.merchantsId = data.venueNameUpdatePage;
      this.form.sportsProjectCode = data.sportsNameUpdatePage;
      this.currentVal = data.roles[0].dataPermission;
    },
    /**
     * @function 获取全部的角色
     */
    getRoleData() {
      this.$http
        .get(`${apis.selectAllRole}?merchantId=${this.merchantAccountDetailInfoDTO.merchantId}`)
        .then((res) => {
          if (res.data.code === 0) {
            this.roleAll = res.data.data;
          }
        });
    },
    // selectAllVenue() {
    //   this.$http.get(apis.selectAllVenue).then((res) => {
    //     if (res.data.code === 0) {
    //       this.venueList = res.data.data;
    //     }
    //   });
    // },
    // 获取部门
    getPermissions() {
      this.$http.get(apis.dept_list_enable).then((res) => {
        this.buildtree(res.data.rows, this.depts, 0);
      });
    },
    buildtree(list, arr, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          const child = {
            key: item.deptId,
            value: item.deptId,
            label: item.deptName,
            children: [],
          };
          this.buildtree(list, child.children, item.deptId);
          if (child.children.length === 0) {
            delete child.children;
          }
          arr.push(child);
        }
      });
    },
  },
};
</script>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
